import { combineReducers } from "@reduxjs/toolkit";
import { sites_overview1_dashboardReducer } from "../tabs/dashboard/states/dashboard.reducers";
import { sites_overview1_mapReducer } from "../tabs/map/states/map.reducers";
import { sites_overview1_operationsReducer } from "../tabs/operations/states/operations.reducers";
import { sites_overview1_performanceReducer } from "../tabs/performance/states/performance.reducers";


export const overview1_Reducers = combineReducers({
    dashboard:sites_overview1_dashboardReducer,
    map:sites_overview1_mapReducer,
    operations:sites_overview1_operationsReducer,
    performance:sites_overview1_performanceReducer,
});