import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { DateTimeRange } from 'models/DateTimeRange';

const MODULE_NAME = "[Sites/Site-1/Performance/Top Nav]";

export const setItems = createAction(`${MODULE_NAME} Init Item`, (payload:{items: string[]}) => ({payload}));
export const selectItem = createAction(`${MODULE_NAME} Select Item`, (payload:{item: string}) => ({payload}));

export const setTimeRange = createAction(`${MODULE_NAME} Set TimeRange`, (payload:{range:DateTimeRange}) => ({payload}));
export const reset = createAction(`${MODULE_NAME} Reset`, (payload:{site:string}) => ({payload}));

