import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
// import {Buffer} from 'buffer';
// import { DashboardConfig } from '../models/DashboardConfig';
// import { IResponse } from '../models/IResponse';
import { PvisClient } from 'api/PvisClient';
import { Overview2OverviewSelectors } from './overview.exports';


const MODULE_NAME = "[Sites/Overview-2/Overview]";
const _http = PvisClient.getInstance();


export const refreshData = createAction(`${MODULE_NAME} Refresh Data`)

export const fetchConfig = createAsyncThunk(
    `${MODULE_NAME} Fetch Config`,
    async({paths}:{paths:string[]},thunkAPI) => {

        // console.log('paths');
        // console.log(paths);
        
        const data = await _http.getSitesConfig(paths);
        // return data;
        return  {paths:paths, config:data};
    
    }
)

// export const fetchData = createAsyncThunk(
//     `${MODULE_NAME} Fetch Data`,
//     async({paths,component}:{paths:string[],component:string},thunkAPI) => {
        
//         const url = `api/data/sites/site-1/dashboard/${component}.json`;
//         const res = await axios.get(url)
//         const data = res.data
//         return {paths:paths, component:component, data:data};
    
//     }
// )

export const fetchDataCurrents = createAsyncThunk(
    `${MODULE_NAME} Fetch Data Currents`,
    async({site,component,tagnames}:{site:string,component:string, tagnames:string[]},thunkAPI) => {
        
        const data = await _http.getDataCurrents(tagnames);
        return {site:site, component:component, data:data};
    
    }
)

export const fetchDataPlots = createAsyncThunk(
    `${MODULE_NAME} Fetch Data Plots`,
    async({site,component,tagnames,startTime,endTime}:{site:string, component:string, tagnames:string[], startTime:Date,endTime:Date},thunkAPI) => {

        const data = await _http.getDataPlots(tagnames,startTime,endTime);
        return {site:site, component:component, data:data};

    }
)
