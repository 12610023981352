import { DateTimeX } from "./DateTimeX";

export interface JwtToken {
  accessToken?:string;
  refreshToken?:string;
  expiry?:Date;
  isRefreshing?:boolean;
  refreshTimeout?:Date;
}

export class JwtService {

  private static instance: JwtService;
  public static getInstance(): JwtService {
      if (!JwtService.instance) { JwtService.instance = new JwtService(); }
      return JwtService.instance;
  }

  private _jwt: JwtToken | undefined = undefined;
  private _subs:((token:JwtToken|undefined) => void)[] = [];
  private TOKEN_KEY = 'token';
  private REFRESH_TOKEN_TIMEOUT_MS = 5000;

  private constructor() { 
    this.initEventListener();
    this.getToken();
    this.notifySubs();
  }

  private initEventListener() {
    window.addEventListener('storage', (event) => {
      if(this._jwt === undefined) { this._jwt = {}; }
      switch(event.key) {
        case this.TOKEN_KEY: {
          // console.log('token in localstorage updated.');
          if(event.newValue === null || event.newValue === undefined) { this._jwt === undefined; }
          else { 
            try {
              this._jwt = this.deserializeToken(event.newValue);
              this.notifySubs();  
            } catch (error) { }
          }
          break;
        }
      }
    });
  }

  public onTokenUpdated(func:(token:JwtToken|undefined) => void) { 
    // console.log('onUpdated');
    this._subs.push(func); 
  }
  
  public getToken():JwtToken|undefined {
    this._jwt = this.readToken();
    // console.log(this._jwt);
    return this._jwt;
  }

  public setToken(token:JwtToken) {
    // console.log('writeToken')
    this.writeToken(token);
  }

  public isExpired():boolean {

    // console.log(DateTimeX.getTimeDiffNowUtcMs(this._jwt?.expiry));
    if(this._jwt === undefined || this._jwt.expiry === undefined || 
      (DateTimeX.getTimeDiffNowUtcMs(this._jwt.expiry) ?? 0) <= 0) { return true; }

    return false;
  }

  public isRefreshing():boolean {
    if(this._jwt === undefined || this._jwt.isRefreshing === undefined) { return false; }
    return this._jwt.isRefreshing;
  }

  public isRefreshingTimeout():boolean {
    if(this._jwt === undefined || this._jwt.refreshTimeout === undefined) { return false; }
    return (DateTimeX.getTimeDiffNowUtcMs(this._jwt.refreshTimeout) ?? 0) < 0;
  }

  public startRefreshing() {
    const newJwt =  { ...this._jwt, 
      isRefreshing:true, 
      refreshTimeout: DateTimeX.addMilliseconds(DateTimeX.nowUtc(),this.REFRESH_TOKEN_TIMEOUT_MS)
    };

    this.writeToken(newJwt);
  }

  public successRefreshing(token:JwtToken) {
    const newJwt =  { 
      accessToken: token.accessToken,
      refreshToken: token.refreshToken,
      expiry: token.expiry,
      isRefreshing:false, 
      refreshTimeout: undefined
    } as JwtToken;

    this.writeToken(newJwt);
  }

  public clearRefreshing() {
    const newJwt =  { 
      ...this._jwt, 
      isRefreshing:false, 
      refreshTimeout: undefined
    };

    this.writeToken(newJwt);
  }

  public clearToken() {
    // console.log('clearToken')
    this.writeToken(undefined);
  }

  private notifySubs() { 
    // console.log('onUpdatedTrigger');
    this._subs?.forEach(x => x(this._jwt)); 
  }

  private readToken():JwtToken|undefined { 
    const ls = localStorage.getItem(this.TOKEN_KEY) ?? undefined;
    // console.log(ls);
    if(ls === undefined) { return undefined; }
    else { 
      try { return this.deserializeToken(ls); } 
      catch (error) { return undefined; }
    }
  }

  private writeToken(value:JwtToken|undefined) {
    this._jwt = value; 
    if(value === undefined) { localStorage.removeItem(this.TOKEN_KEY); }
    else { localStorage.setItem(this.TOKEN_KEY,JSON.stringify(value)); }
    this.notifySubs();  
  }

  private deserializeToken(txt:string|undefined|null):JwtToken|undefined {
    
    try {
      if(txt === undefined || txt === null) { return undefined; }
      const raw = JSON.parse(txt);
      return {
        accessToken: raw.accessToken === null || raw.accessToken === undefined ? undefined : raw.accessToken,
        refreshToken: raw.refreshToken === null || raw.refreshToken === undefined ? undefined : raw.refreshToken,
        expiry: raw.expiry === null || raw.expiry === undefined ? undefined : new Date(new Date(raw.expiry).toUTCString()),
        isRefreshing: raw.isRefreshing === null || raw.isRefreshing === undefined ? undefined : raw.isRefreshing.toString().toLowerCase() === 'true',
        refreshTimeout: raw.refreshTimeout === null || raw.refreshTimeout === undefined ? undefined : new Date(new Date(raw.refreshTimeout).toUTCString()),
      } as JwtToken
    } 
    catch (error) { 
      // console.log(error);
      return undefined; }
  }
}