import { createReducer } from "@reduxjs/toolkit";
import { selectItem, setItems } from "./top-nav.actions";

interface TopNavState {
    items:string[],
    selectedItem:string
}


const initialState:TopNavState = {
    items:[],
    selectedItem:''
};


export const sites_TopNavReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setItems, (state, action) => {
        return { 
            ...state,
            items: action.payload.items,
            selectedItem:action.payload.items[0]
            // selectedItem:action.payload.items[2]
        };
    })
    .addCase(selectItem, (state,action) => {
        return { 
            ...state,
            selectedItem:action.payload.item
        };
    })
)
