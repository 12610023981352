import { createReducer } from "@reduxjs/toolkit";
import { backItem, fetchConfig, fetchDataCurrents, filterItem, nextItem, selectItem, setLoadingFalse, setLoadingTrue } from "./sites-nav.actions";
import { SiteNavModel } from "models/SiteNavModel";
import { TsTagRecord} from "models/time-series/TsTagRecord"
import { stat } from "fs";
import { push } from "react-router-redux";

interface SitesLeftState {
    loading:boolean,
    error?:string,
    search?:string,
    paths:string[],
    selectedItem?:SiteNavModel,
    items?:SiteNavModel[]
    previousItems?:SiteNavModel[][]
    viewItems?:SiteNavModel[],
    filteredViewItems?:SiteNavModel[],
    values:TsTagRecord[],
    fullPaths:string[]
}


const initialState:SitesLeftState = {
    loading:false,
    error:undefined,
    search:undefined,
    paths:[],
    selectedItem:undefined,
    items:undefined,
    previousItems:[],
    viewItems:undefined,
    filteredViewItems:undefined,
    values:[],
    fullPaths:[]
};


export const sites_SitesNavReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setLoadingTrue, (state) => {
        return { 
            ...state,
            loading: true,
            error:undefined
        };
    })
    .addCase(setLoadingFalse, (state) => {
        return { 
            ...state,
            loading: false,
            error:undefined
        };
    })
    .addCase(selectItem, (state,action) => {

      let fullPaths = [];
      
      if(action.payload.item.roots !== undefined) { fullPaths.push(...action.payload.item.roots); }
      else { fullPaths.push(...state.paths); }

      fullPaths.push(action.payload.item.path ?? '');

        return { 
            ...state,
            selectedItem: action.payload.item,
            // fullPaths: [...state.paths, action.payload.item.roots ? action.payload.item.path ?? '']
            fullPaths: fullPaths,
            // fullPaths: [...action.payload.item.fullPaths ?? '']
        };
    })
    .addCase(filterItem, (state,action) => {
        return { 
            ...state,
            search:action.payload.keyword
        };
    })
    .addCase(nextItem, (state,action) => {

        let filteredItems = state.viewItems?.filter(x => x.path === action.payload.item.path);
        let previousItems= [...(state.previousItems === undefined ? [] : state.previousItems) , state.viewItems === undefined ? [] : state.viewItems];

        let viewItems = filteredItems === undefined ? undefined : filteredItems[0].children;
        let selectedItem = viewItems === undefined || viewItems.length === 0 ? undefined : viewItems[0];
        let paths = [...state.paths ?? [], action.payload.item.path];

        return { 
            ...state,
            paths: paths,
            previousItems: previousItems,
            viewItems: viewItems,
            selectedItem:selectedItem,
            search: '',
            fullPaths: [...paths,selectedItem?.path ?? '']
        };
    })

    .addCase(backItem, (state) => {

        let paths = state.paths === undefined || state.paths.length === 0 ? [] : state.paths.splice(0,state.paths.length - 2);
        // console.log('paths');
        // console.log(paths);
        
        let previousItems = state.previousItems === undefined ? [] : [...state.previousItems];
        // console.log('previousItems');
        // console.log(previousItems);
       
        let viewItems = previousItems?.pop();
        let selectedItem = viewItems === undefined || viewItems.length === 0 ? undefined : viewItems[0];

        return { 
            ...state,
            paths: paths,
            viewItems: viewItems,
            selectedItem:selectedItem,
            previousItems: previousItems,
            search: '',
            fullPaths: [...paths,selectedItem?.path ?? '']
        };
    })
    .addCase(fetchConfig.pending, (state, action) => {
        return { 
            ...state,
            // loading:true,
            items: [],
            error:undefined
        };
    })
    .addCase(fetchConfig.fulfilled, (state, action) => {

        let values:TsTagRecord[] = [];

        let now = Date.now();

        
        // action.payload.forEach(c0x => {            
        //     values.push( { tagname:c0x.tagname, timestamp:now, value: Math.random() * (c0x.max ?? 0), status:''} );

        //     let c1 = c0x.children;
        //     if(c1 && c1.length > 0) {
        //         c1.forEach(c1x => {
        //             values.push( { tagname:c1x.tagname, timestamp:now, value: Math.random() * (c1x.max ?? 0), status:''} );
        //             let c2 = c1x.children;
        //             if(c2 && c2.length > 0) {
        //                 c2.forEach(c2x => {
        //                     values.push( { tagname:c2x.tagname, timestamp:now, value: Math.random() * (c2x.max ?? 0), status:''} );
        //                 })
        //             }
        //         })
        //     }
        // });

        let items = action.payload;

        // console.log(items);

        if(items !== undefined) {

          // items = [...items.slice(1,items.length)];
          items.forEach(x => {
            if(x.children !== undefined && x.children.length > 0) {

              x.children.forEach(y => {
                if(y.view !== 'site-1') { return; }
                // console.log(y);
                y.fullPaths = [];
                // let z = y;
                y.fullPaths.push(x.path);
                // console.log(y.roots);
                // if(y.roots != undefined) { y.fullPaths.push(...x.roots); }
                y.fullPaths.push(y.path);
                // console.log(y.fullPaths);
              })
            }
  
            // return z;
            
          });
        }
        

        // return { 
        //     ...state,
        //     // loading:false,
        //     paths: [],
        //     items:action.payload,
        //     viewItems:action.payload,
        //     selectedItem:action.payload[0],
        //     error:undefined,
        //     fullPaths: [action.payload[0].path]
        //     // values:values,
        // };

        return { 
          ...state,
          // loading:false,
          paths: [],
          items:items,
          viewItems:items,
          selectedItem:items[0],
          error:undefined,
          // fullPaths: [items[0].path]
          // fullPaths: [...items[0].roots,items[0].path]
          fullPaths: [...(items[0].roots === undefined ? [] : items[0].roots),items[0].path]
          // values:values,
      };
    })
    .addCase(fetchConfig.rejected, (state, action) => {
        return { 
            ...state,
            // loading:false,
            items: [],
            error:action.error.message
        };
    })

    .addCase(fetchDataCurrents.pending, (state) => {
        
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:undefined,
        };
    })
    .addCase(fetchDataCurrents.fulfilled, (state, action) => {

        let res = action.payload as TsTagRecord[];
        // console.log(res);
        res = res?.map(x => {return {...x, value: x.value/1000}})
        
        return { 
            ...state,
            isDataLoading: false,
            dataLoadingError:undefined,
            values:res,
        };
    })
    .addCase(fetchDataCurrents.rejected, (state, action) => {

        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:action.error.message,
            // data:undefined,
        };
    })
    
    
)
