import { createReducer } from "@reduxjs/toolkit";
import { selectItem, setItems } from "./top-nav.actions";
import { SetObjectValue } from "utils/objectUils";

interface TopNavState {
    items:any,
    selectedItem:any
}


const initialState:TopNavState = {
    items:undefined,
    selectedItem:undefined
};

export const topNavReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setItems, (state, action) => {

      let stateItems:any = SetObjectValue(state.items,action.payload.paths,action.payload.items);
      let stateSelectedItem:any = SetObjectValue(state.selectedItem,action.payload.paths,action.payload.items[0]);
      
      // console.log(stateItems);

      return { 
          ...state,
          items: stateItems,
          selectedItem:stateSelectedItem
      };
    })
    .addCase(selectItem, (state,action) => {

      let stateSelectedItem:any = SetObjectValue(state.selectedItem,action.payload.paths,action.payload.item);
      // console.log(stateSelectedItem);

      return { 
          ...state,
          selectedItem:stateSelectedItem
      };
    })
)
