import { PvisClient } from "api/PvisClient";
import { AuthToken } from "pages/login/models/AuthToken";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { DateTimeX } from "utils/DateTimeX";

import { useNavigate } from 'react-router-dom';
import { JwtService } from "utils/jwtService";
import { User } from "models/User";


export interface AuthContextProps {
  isAuth?:boolean;
  // token?:AuthToken;
  // setToken?: (token:AuthToken) => void;
  logout?: () => void;
  login?:(username:string, password:string) => void;
  user?:User;
}

export const AuthContext = createContext<Partial<AuthContextProps>>({});
//               HH:mm:ss:ms 
const expireMs = 8*60*60*1000*-1;

const checkAuth = (token:AuthToken|undefined) => {

  // if(token !== undefined && 
  //   token.accessToken !== undefined &&
  //   token.refreshToken !== undefined ) { return true; }

  if(token === undefined || 
      token.accessToken === undefined || 
      token.refreshToken === undefined || 
      token.expiry === undefined) { return false; }

  var remainSec = getTokenLifeTimeRemainMs(token);
  // console.log(expireMs);
  // console.log(remainSec);
  // console.log(remainSec < expireMs);
  if(remainSec < expireMs) { return false; }
  return true;
}

const getTokenLifeTimeRemainMs = (token:AuthToken|undefined) => {
  // console.log(token);
  if(token === undefined || token.expiry === undefined || token.expiry === null) { return -9999; }
  return token.expiry.getTime() - DateTimeX.nowUtc().getTime();
}

// const getLocalStorage = ():AuthToken | undefined => {
//   return (
//     localStorage.getItem("accessToken") === null || 
//     localStorage.getItem("refreshToken") === null || 
//     localStorage.getItem("expiry") === null
//   ) ? undefined 
//   :
//     { 
//       accessToken:localStorage.getItem("accessToken") ?? undefined,
//       refreshToken:localStorage.getItem("refreshToken") ?? undefined,
//       expiry:localStorage.getItem("expiry") === undefined || localStorage.getItem("expiry") === null ? 
//               undefined : 
//               new Date(localStorage.getItem("expiry") ?? ''),
//     } as AuthToken | undefined

// }

export const AuthProvider = ({children}:{children:JSX.Element}) => {
  
  const _jwtStorage = JwtService.getInstance();
  const _http = PvisClient.getInstance();
  // const [token, _setToken] = useState(getLocalStorage());
  const [token, _setToken] = useState(_jwtStorage.getToken());
  const [isAuth, setIsAuth] = useState(checkAuth(_jwtStorage.getToken()));
  const [user, setUser] = useState(undefined as undefined | User);
  const navigate = useNavigate();
  // const [isAuth, setIsAuth] = useState(undefined as boolean | undefined);
  

  let _timer:NodeJS.Timeout;

  async function fetchUser() {
    try {
      const user = await _http.getUser();
      // console.log(user);
     if(user !== undefined) { setUser(user); }
    } catch (e) {
        console.error(e);
    }
  };

  useEffect(() => {
    // console.log('TokenUpdated subscribe.')
    // _http.onTokenUpdated(tokenUpdatedHandler);
    _jwtStorage.onTokenUpdated(tokenUpdatedHandler);
    fetchUser();
  },[])
  
  
  const tokenUpdatedHandler = (newToken:AuthToken|undefined) => {
    // console.log('TokenUpdatedHandler called.')
    _setToken(newToken);
    setIsAuth(checkAuth(newToken));
    fetchUser();
  }

  const login = async(username:string, password:string) => {
    await _http.login({username,password});
  }

  const logout = () => {

    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('refreshToken');
    // localStorage.removeItem('expiry');

    _jwtStorage.clearToken();

    _setToken(undefined);
    setIsAuth(false);

    navigate('/login');
  };
  
  const contextValue = useMemo(
    () => ({
      isAuth:isAuth,
      // token:token,
      logout:logout,
      login:login,
      user:user,
    } as AuthContextProps),
    [token,user]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = ():AuthContextProps => {
  return useContext(AuthContext);
};

export default AuthProvider;