import { createReducer } from "@reduxjs/toolkit";
import { selectItem, setItems, setTimeRange } from "./top-nav.actions";
import { DateTimeRange } from "models/DateTimeRange";
import { DateTimeX } from "utils/DateTimeX";

interface TopNavState {
    items:string[],
    selectedItem:string,
    timeRange:DateTimeRange;
}


const initialState:TopNavState = {
    items:[],
    selectedItem:'',
    timeRange:{startTime:DateTimeX.BOM(0), endTime:DateTimeX.EOD()} as DateTimeRange
};


export const sites_site1_performance_topNavReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setItems, (state, action) => {
        return { 
            ...state,
            items: action.payload.items,
            selectedItem:action.payload.items[0]
        };
    })
    .addCase(selectItem, (state,action) => {
        return { 
            ...state,
            selectedItem:action.payload.item
        };
    })

    .addCase(setTimeRange, (state, action) => {

      return { 
          ...state,
          timeRange: action.payload.range
      };
    })
)
