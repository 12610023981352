import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

const MODULE_NAME = "[Counter]";

export const increment = createAction(`${MODULE_NAME} Increment`, (payload:{number: number}) => ({payload}));
export const decrement = createAction(`${MODULE_NAME} Decrement`, (payload:{number: number}) => ({payload}));
export const reset = createAction(`${MODULE_NAME} Reset`);

// export const fetchApi = createAsyncThunk(`${MODULE_NAME} Fetch Api`, 
//     async (dispatch,getState) => {
//         const response = await fetch('https://fakestoreapi.com/products/1');
//         const data = await response.json();
//         console.log(data);
//         return {payload:data};
//     });


