import { createReducer } from "@reduxjs/toolkit";
import { init, login, logout } from "./auth.actions";
import { DateTimeX } from "utils/DateTimeX";

interface LoginState {
   accessToken?:string;
   refreshToken?:string;
   expiry?:Date;
   loading:boolean;
   error?:string;
}


const initialState:LoginState = {
  accessToken:undefined,
  refreshToken:undefined,
  expiry:undefined,
  loading:false,
  error:undefined,
};


export const authReducer = createReducer(initialState, (builder) =>
  builder
  .addCase(init, (state) => {

    const accessToken = localStorage.getItem("accessToken") ?? undefined;
    const refreshToken = localStorage.getItem("refreshToken") ?? undefined;
    const strExpiry = localStorage.getItem("expiry") ?? undefined;

    let expiry:Date|undefined;
    if(strExpiry !== undefined) { expiry = new Date(strExpiry); }

    return { 
        ...state,
        accessToken: accessToken,
        refreshToken: refreshToken,
        expiry: expiry
    };
  })

  .addCase(logout, (state) => {

    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiry");

    return { 
        ...state,
        accessToken: undefined,
        refreshToken: undefined,
        expiry: undefined
    };
  })

    .addCase(login.pending, (state) => {
        return { 
            ...state,
            loading:true,
            error:undefined,
        };
    })
    .addCase(login.fulfilled, (state, action) => {

      let expiry:Date|undefined;

      
      // console.log(action.payload.accessToken);
      // console.log(action.payload.refreshToken);
      // console.log(action.payload.expireSec);

      if(action.payload.expireSec !== undefined) {
        expiry = DateTimeX.addSeconds(DateTimeX.now(),action.payload.expireSec);
      }

      if(action.payload.accessToken === undefined || action.payload.refreshToken === undefined || action.payload.expireSec === undefined){
        localStorage.setItem("accessToken", '');
        localStorage.setItem("refreshToken", '');
        localStorage.setItem("expiry", '');
      }
      else {
        localStorage.setItem("accessToken", action.payload.accessToken);
        localStorage.setItem("refreshToken", action.payload.refreshToken);
        localStorage.setItem("expiry", DateTimeX.addSeconds(DateTimeX.nowUtc(),action.payload.expireSec).toISOString());
      }


        return { 
            ...state,
            loading: false,
            error:undefined,
            accessToken:action.payload.accessToken,
            refreshToken:action.payload.refreshToken,
            expiry:expiry,
        };
    })
    .addCase(login.rejected, (state, action) => {

        localStorage.setItem("accessToken", '');
        localStorage.setItem("refreshToken", '');
        localStorage.setItem("expireSec", '');

        return { 
            ...state,
            loading: false,
            error:action.error.message,
            accessToken:undefined,
            refreshToken:undefined,
            expiry:undefined,
        };
    })

    
    
)


