import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
// import {Buffer} from 'buffer';
// import { DashboardConfig } from '../models/DashboardConfig';
// import { IResponse } from '../models/IResponse';
import { PvisClient } from 'api/PvisClient';
import { TsTimeMode } from 'enums/TsTimeMode';
import { DateTimeRange } from 'models/DateTimeRange';


const MODULE_NAME = "[Sites/Site-1/Data]";
const _http = PvisClient.getInstance();


export const refreshData = createAction(`${MODULE_NAME} Refresh Data`)

export const fetchConfig = createAsyncThunk(
    `${MODULE_NAME} Fetch Config`,
    async({paths}:{paths:string[]},thunkAPI) => {

        // console.log('paths');
        // console.log(paths);
        
        const data = await _http.getSitesConfig(paths);
        // return data;
        return  {paths:paths, config:data};
    
    }
)

export const fetchDataCurrents = createAsyncThunk(
    `${MODULE_NAME} Fetch Data Currents`,
    async({site,component,tagnames}:{site:string,component:string, tagnames:string[]},thunkAPI) => {
        
        const data = await _http.getDataCurrents(tagnames);
        return {site:site, component:component, data:data};
    
    }
)

export const fetchDataPlots = createAsyncThunk(
    `${MODULE_NAME} Fetch Data Plots`,
    async({site,component,tagnames,startTime,endTime}:{site:string, component:string, tagnames:string[], startTime:Date,endTime:Date},thunkAPI) => {

        const data = await _http.getDataPlots(tagnames,startTime,endTime);
        return {site:site, component:component, data:data};

    }
)

export const fetchDataInterval = createAsyncThunk(
  `${MODULE_NAME} Fetch Data Plots`,
  async({site,component,tagnames,startTime,endTime,interval,timeMode}:
    {site:string, component:string, tagnames:string[], startTime:Date,endTime:Date,interval:string,timeMode?:TsTimeMode},thunkAPI) => {

      const data = await _http.getDataInterval(tagnames,startTime,endTime,interval,timeMode);
      return {site:site, component:component, data:data};

  }
)


export const fetchDataAtTimes = createAsyncThunk(
  `${MODULE_NAME} Fetch Data AtTime`,
  async({site,component,tagnames,timestamp,timeMode}:
    {site:string, component:string, tagnames:string[], timestamp:Date, timeMode?:TsTimeMode},thunkAPI) => {

      const data = await _http.getDataAtTimes(tagnames,timestamp,timeMode);
      return {site:site, component:component, data:data};

  }
)

// export const fetchData = createAsyncThunk(
//   `${MODULE_NAME} Fetch Data Plots`,
//   async({site,component,tagnames,startTime,endTime}:{site:string, component:string, tagnames:string[], startTime:Date,endTime:Date},thunkAPI) => {

//       const data = await _http.getDataPlots(tagnames,startTime,endTime);
//       return {site:site, component:component, data:data};

//   }
// )


export const setTimeRange = createAction(`${MODULE_NAME} Set TimeRange`, (payload:{range:DateTimeRange}) => ({payload}));
export const reset = createAction(`${MODULE_NAME} Reset`, (payload:{site:string}) => ({payload}));
