import { createContext, useContext, useEffect, useMemo, useState } from "react";


export interface VisibilityContextProps {
  visible?:boolean;
}

export const VisibilityContext = createContext<Partial<VisibilityContextProps>>({});

export const VisibilityProvider = ({children}:{children:JSX.Element}) => {
  const [visible, setVisible] = useState(true);
 
  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChange);
    }
  },[])

  const visibilityChange = () => {
    if (document.visibilityState === "visible") {
        // console.log("Window is visible");
        setVisible(true);
    } else {
        // console.log("Window is not visible");
        setVisible(false);
    }
};
  
  const contextValue = useMemo(
    () => ({
      visible:visible
    } as VisibilityContextProps),
    [visible]
  );

  return (
    <VisibilityContext.Provider value={contextValue}>{children}</VisibilityContext.Provider>
  );
};

export const useVisibility = ():VisibilityContextProps => {
  return useContext(VisibilityContext);
};

// export default VisibilityProvider;