export const formatNumber = (number:string|number|null|undefined, digit:number):string => {
  
  if(number == null || number == undefined) {return '-';}

  if(typeof number === 'string'){
    let parseResult = parseFloat(number);
    if(isNaN(parseResult)){ return number;}
    number = parseResult;
  }

  return number.toFixed(digit).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}