// import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { SiteNavModel } from 'models/SiteNavModel';
import { createSelector, createDraftSafeSelector } from '@reduxjs/toolkit'
import { AppState } from 'states/app.state';


export const getState = (state: AppState) => state.sites.sitesNav;
// export const getState = createSelector((state: AppState) => state.counter, (state) => state);

export const getAllItems = createSelector(getState, (state) => state.items);
export const getViewItems = createSelector(getState, (state) => state.viewItems);
export const getSearch = createSelector(getState, (state) => state.search);
export const getView = createSelector(getState, (state) => state.selectedItem?.view);
export const getFilteredViewItems = createSelector(getState, (state) => {
    // console.log("getFilteredViewItems");
    // console.log("state.search");
    // console.log(state.search);
    if(state.search === undefined || state.search.length === 0) { return  state.viewItems; }
    else{ 

      // const level = state.fullPaths === undefined ? 0 : state.fullPaths.length;
      // if(level === 1) {

      //   const allItems = state.items === undefined ? undefined : 
      //                     state.items.flatMap(x => x.children)
      //                     .filter(x => x !== undefined)
      //                     .filter(x => x.view === 'site-1');
      //   // console.log(allItems);
      //   if(allItems === undefined) { return state.viewItems; }

      //   return allItems.filter(x => x.title.toLowerCase().includes((state.search ?? '').toLowerCase())) as SiteNavModel[]
      // }
      // else {
      //   return state.viewItems?.filter(x => x.title.toLowerCase().includes((state.search ?? '').toLowerCase())) as SiteNavModel[]
      // }

      // const level = state.fullPaths === undefined ? 0 : state.fullPaths.length;
      // console.log(level);
      // console.log(state.viewItems);
      // const allItems = state.items === undefined ? undefined : 
      //                     [...state.items
      //                       .filter(x => x !== undefined)
      //                       .filter(x => x.view === 'site-1'), 
      //                       ...state.items.flatMap(x => x.children)
      //                     .filter(x => x !== undefined)
      //                     .filter(x => x.view === 'site-1')];

      const allItems = state.viewItems === undefined ? undefined : 
      [...state.viewItems
        .filter(x => x !== undefined)
        .filter(x => x.view === 'site-1'), 
        ...state.viewItems.flatMap(x => x.children)
      .filter(x => x !== undefined)
      .filter(x => x.view === 'site-1')];
        // console.log(allItems);
        if(allItems === undefined) { return state.viewItems; }

        return allItems.filter(x => x.title.toLowerCase().includes((state.search ?? '').toLowerCase())) as SiteNavModel[]
      
    }
});
export const getSelectedItem = createSelector(getState, (state) => state.selectedItem);
export const getLoading = createSelector(getState, (state) => state.loading);
export const getPaths = createSelector(getState, (state) => state.paths);
// export const getFullPaths = createSelector(getState, (state) => [...state.paths, state.selectedItem?.path ?? '' ]);
// export const getFullPaths = createSelector(getState, (state) => state.fullPaths);
export const getFullPaths = createSelector(getState, (state) => state.selectedItem?.fullPaths ?? state.fullPaths);
// export const getFullPaths2 = createSelector(getState, (state) => [...state.paths, state.selectedItem?.path ?? '' ].join(','));
export const getValue = (tagname:string) => createSelector(getState, (state) => state.values.filter(x => x.tagname === tagname)[0])
export const getAllValues = createSelector(getState, (state) => state.values)



