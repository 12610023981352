import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { PvisClient } from 'api/PvisClient';
import { EquipmentConfig, ParameterConfig } from '../models/CategoryConfig';
import { AssetCatagory, AssetEquipment, AssetParameter } from '../models/AssetsPickerModel';
import { CheckBoxGroupItem } from 'components/forms/check-box/models/CheckBoxModels';


const MODULE_NAME = "[Widgets/Assets-Picker]";
const _http = PvisClient.getInstance();


export const refreshData = createAction(`${MODULE_NAME} Refresh Data`)

export const fetchConfig = createAsyncThunk(
  `${MODULE_NAME} Fetch Config`,
  async({paths}:{paths:string[]},thunkAPI) => {

      // console.log('paths');
      // console.log(paths);
      
      const data = await _http.getSitesConfig(paths);
      // return data;
      return  {paths:paths, config:data};
  
  }
)
// export const initEquipments = createAction(`${MODULE_NAME} Init Equipments`, (payload:{paths:string[], items: EquipmentConfig[]}) => ({payload}));
// export const initParameters = createAction(`${MODULE_NAME} Init Parameters`, (payload:{paths:string[], items: ParameterConfig[]}) => ({payload}));


// export const selectEquipments = createAction(`${MODULE_NAME} Select Equipments`, (payload:{paths:string[], items: EquipmentConfig[]}) => ({payload}));
// export const selectParameters = createAction(`${MODULE_NAME} Select Parameters`, (payload:{paths:string[], items: ParameterConfig[]}) => ({payload}));

export const updateExpand = createAction(`${MODULE_NAME} Update Expand`, (payload:{paths:string, catId:string, expanded:boolean}) => ({payload}));
export const updateEquipments = createAction(`${MODULE_NAME} Update Equipments`, (payload:{paths:string, catId:string, items:CheckBoxGroupItem[]}) => ({payload}));
export const updateParameters = createAction(`${MODULE_NAME} Update Parameters`, (payload:{paths:string, catId:string, items:CheckBoxGroupItem[]}) => ({payload}));
