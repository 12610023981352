import { createReducer } from "@reduxjs/toolkit";
import { fetchProducts, setLoadingFalse, setLoadingTrue } from "./fake-products.actions";

export interface FakeProduct {
    id:number,
    title:string,
    category: string,
    description:string,
    image:string,
    price:number,
    rating:{ 
        count:number,
        rate:number
    }
}

interface FakeProductState {
    loading:boolean,
    products:FakeProduct[],
    error?:string,
}


const initialState:FakeProductState = {
    loading:false,
    products:[],
    error:undefined
};


export const fakeProductsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setLoadingTrue, (state) => {
        return { 
            ...state,
            loading: true,
            error:undefined
        };
    })
    .addCase(setLoadingFalse, (state) => {
        return { 
            ...state,
            loading: false,
            error:undefined
        };
    })
    .addCase(fetchProducts.pending, (state, action) => {
        return { 
            ...state,
            loading:true,
            products: [],
            error:undefined
        };
    })
    .addCase(fetchProducts.fulfilled, (state, action) => {
        return { 
            ...state,
            loading:false,
            products: action.payload,
            error:undefined
        };
    })
    .addCase(fetchProducts.rejected, (state, action) => {
        return { 
            ...state,
            loading:false,
            products: [],
            error:action.error.message
        };
    })
    
    
)
