import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { counterReducer } from './counter/counter.reducers'
import { fakeProductsReducer } from './fake-products/fake-products.reducers'

import { sitesReducers } from 'pages/sites/states/sites.reducers';
import { topNavReducer } from 'widgets/top-nav/states/top-nav.reducers';
import { widgetsReducers } from 'widgets/states/widgets.reducers';
import { authReducer } from 'pages/login/states/auth.reducers';


export const store = configureStore({
    reducer: {
      auth:authReducer,
      topNav:topNavReducer,
      widgets:widgetsReducers,
      sites:sitesReducers,
      counter:counterReducer,   
      fakeProducts:fakeProductsReducer,
      // routing: routerReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
})

export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;