export class DateTimeX {

  static now():Date {
      let now = new Date();
      // now = this.addMinutes(now, -1 * now.getTimezoneOffset());
      return now;
  }

  static nowUtc():Date {
    const now = new Date();

    const year = now.getUTCFullYear();
    const month = now.getUTCMonth();
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();
    const milliseconds = now.getUTCMilliseconds();
    return new Date(Date.UTC(year, month,day, hours,minutes,seconds,milliseconds));
  }

  static getTimeDiffMs(start:Date|undefined, end:Date|undefined):number|undefined {
    if(start === undefined || end === undefined) { return undefined; }
    return start.getTime() - end.getTime();
  }

  static getTimeDiffNowUtcMs(date:Date|undefined):number|undefined {
    if(date === undefined) { return undefined; }
    return date.getTime() - this.nowUtc().getTime();
  }

  static today():Date {
      let now = new Date();
      // now = this.addMinutes(now, -1 * now.getTimezoneOffset());
      return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  static BOY(date:Date):Date {
    let boy = new Date(date.getFullYear(), 0, 1);
    return boy;
  }

  static BOM(month:number):Date {
      let now = new Date();
      let date = new Date(now.getFullYear(), now.getMonth(), 1);
      return this.addMonths(date, month);
  }

  static EOM(month:number):Date {
      return this.addMonths(this.BOM(month),1);
  }

  static yesterday():Date {
      return new Date(this.addDays(this.today(),-1));
  }
  static EOD():Date {
      return new Date(this.addDays(this.today(),1));
  }

  static EOD235959(date: Date):Date {
      return this.addSeconds(this.addDays(date,1),-1);
  }

  static DDD : string[] = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
  static getDayDDD(date: Date | number):string {
    if(typeof(date) === 'number'){return this.DDD[date as number];}
    let day = date.getDay();
    return this.DDD[day];
  }

  static MMM : string[] = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  static getMonthMMM(date: Date | number):string {
    if(typeof(date) === 'number'){return this.MMM[date as number];}
    let month = date.getMonth();
    return this.MMM[month];
  }

  static EOM235959(date: Date):Date {
      let bom = new Date(date.getFullYear(), date.getMonth(), 1);
      let eom = this.addMonths(bom, 1);
      return this.addSeconds(eom,-1);
  }

  static EOY235959(date: Date):Date {
    let boy = new Date(date.getFullYear()+1, 0, 1);
    let eoy = this.addSeconds(boy, -1);
    return eoy;
  }

  static addMilliseconds(date: Date, ms: number): Date {
    let dt = new Date(date);
    return new Date(dt.setMilliseconds(dt.getMilliseconds() + ms));
  }

  static addSeconds(date: Date, seconds: number): Date {
      let dt = new Date(date);
      return new Date(dt.setSeconds(dt.getSeconds() + seconds));
  }

  static addMinutes(date: Date, minutes: number): Date {
      let dt = new Date(date);
      return new Date(dt.setMinutes(dt.getMinutes() + minutes));
  }

  static addHours(date: Date, hours: number): Date {
      let dt = new Date(date);
      return new Date(dt.setHours(dt.getHours() + hours));
  }

  static addDays(date: Date, days: number): Date {
      let dt = new Date(date);
      return new Date(dt.setDate(dt.getDate() + days));
  }


  static addMonths(date: Date, months: number): Date {
      let dt = new Date(date);
      return new Date(dt.setMonth(dt.getMonth() + months));
  }

  static addYears(date: Date, years: number): Date {
    let dt = new Date(date);
    return new Date(dt.setFullYear(dt.getFullYear() + years));
  }
}