import { combineReducers } from "@reduxjs/toolkit";
import { sites_overview2_overviewReducer } from "../tabs/overview/states/overview.reducers";
import { sites_overview2_trendsReducer } from "../tabs/trends/states/trends.reducers";
import { sites_overview2_siteListReducer } from "../tabs/site-list/states/site-list.reducers";


export const overview2_Reducers = combineReducers({
    overview:sites_overview2_overviewReducer,
    trends:sites_overview2_trendsReducer,
    siteList:sites_overview2_siteListReducer,
    
});