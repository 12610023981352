import { createReducer } from "@reduxjs/toolkit";
import { fetchConfig, fetchDataCurrents, fetchDataPlots, initEquipments, initParameters, initSheets, refreshData, selectEquipments, selectParameters, selectSheet } from "./realtime.actions";
import { SetObjectValue } from "utils/objectUils";

interface RealTimeState {
    isConfigLoading:boolean;
    configLoadingError?:string;
    // config?:DashboardConfig;
    // defaultConfig?:IResponse[];
    config?:any;
    isDataLoading:boolean;
    dataLoadingError?:string;
    data?:any;
    refreshData:boolean;
    equipments?:any;
    selectedEquipments?:any;
    parameters?:any;
    selectedParameters?:any;
    sheetNames?:any;
    selectedSheetName?:any;
}


const initialState:RealTimeState = {
    refreshData:false,
    isConfigLoading:false,
    configLoadingError:undefined,
    config:undefined,
    isDataLoading:false,
    dataLoadingError:undefined,
    data:undefined,
    equipments:undefined,
    selectedEquipments:undefined,
    parameters:undefined,
    selectedParameters:undefined,
    sheetNames:undefined,
    selectedSheetName:undefined,
};


export const sites_site1_realtimeReducer = createReducer(initialState, (builder) =>
  builder
  .addCase(refreshData, (state) => {
        return { 
            ...state,
            refreshData: !state.refreshData
        };
    })

    .addCase(fetchConfig.pending, (state) => {
        return { 
            ...state,
            isConfigLoading: true,
            // config:undefined,
            // data:undefined,
            configLoadingError:undefined
        };
    })
    .addCase(fetchConfig.fulfilled, (state, action) => {

        // console.log(action.payload.paths);
        // var path = action.payload.paths.join('_').replace('-','_').toLowerCase();
        var path = action.payload.paths[action.payload.paths.length-1] ?? '';
        path = path.toLowerCase();
        // console.log(path);
        var config:any = state.config === undefined ? {} : {...state.config};
        config[path] = action.payload.config;

        return { 
            ...state,
            isConfigLoading: false,
            configLoadingError:undefined,
            config:config,
        };
    })
    .addCase(fetchConfig.rejected, (state, action) => {
        return { 
            ...state,
            isConfigLoading: true,
            config:undefined,
            configLoadingError:action.error.message,
        };
    })

    
    // .addCase(fetchData.pending, (state) => {
    //     return { 
    //         ...state,
    //         isDataLoading: true,
    //         dataLoadingError:undefined,
    //     };
    // })
    // .addCase(fetchData.fulfilled, (state, action) => {
    //     var component = action.payload.component;
    //     var data:any = state.data === undefined ? {} : {...state.data};
        
    //     data[component] = action.payload.data;
        
    //     return { 
    //         ...state,
    //         isDataLoading: false,
    //         dataLoadingError:undefined,
    //         data:data,
    //     };
    // })
    // .addCase(fetchData.rejected, (state, action) => {

       
        
    //     return { 
    //         ...state,
    //         isDataLoading: true,
    //         dataLoadingError:action.error.message,
    //         // data:undefined,
    //     };
    // })


    .addCase(fetchDataCurrents.pending, (state) => {
        
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:undefined,
        };
    })
    .addCase(fetchDataCurrents.fulfilled, (state, action) => {
        let site = action.payload.site.toLowerCase();
        let component = action.payload.component;
        let data:any = state.data === undefined ? {} : {...state.data};
        
        if(data[site] === undefined) { data[site] = {}; }
        else { data[site] = {...data[site]}; } 

        data[site][component] = action.payload.data;
        
        return { 
            ...state,
            isDataLoading: false,
            dataLoadingError:undefined,
            data:data,
        };
    })
    .addCase(fetchDataCurrents.rejected, (state, action) => {

        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:action.error.message,
            // data:undefined,
        };
    })


    .addCase(fetchDataPlots.pending, (state) => {
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:undefined,
        };
    })
    .addCase(fetchDataPlots.fulfilled, (state, action) => {
        var site = action.payload.site.toLowerCase();
        var component = action.payload.component;
        var data:any = state.data === undefined ? {} : {...state.data};

        // if(data[site] === undefined){data[site] = {}}
        
        // data[site][component] = action.payload.data;


        if(data[site] === undefined) { data[site] = {}; }
        else { data[site] = {...data[site]}; } 

        data[site][component] = action.payload.data;
        
        return { 
            ...state,
            isDataLoading: false,
            dataLoadingError:undefined,
            data:data,
        };
    })
    .addCase(fetchDataPlots.rejected, (state, action) => {
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:action.error.message,
            // data:undefined,
        };
    })

    .addCase(initEquipments, (state, action) => {
      let equips:any = SetObjectValue(state.equipments,action.payload.paths,action.payload.items);
      return { 
          ...state,
          equipments: equips,
      };
    })

    .addCase(initParameters, (state, action) => {
      let params:any = SetObjectValue(state.parameters,action.payload.paths,action.payload.items);
      // console.log(params);
      return { 
          ...state,
          parameters: params,
      };
    })

    .addCase(initSheets, (state, action) => {
      let sheets:any = SetObjectValue(state.sheetNames,action.payload.paths,action.payload.sheetNames);
      // console.log(sheets);
      return { 
          ...state,
          sheetNames: sheets,
      };
    })

    .addCase(selectEquipments, (state, action) => {
      let equips:any = SetObjectValue(state.selectedEquipments,action.payload.paths,action.payload.items);
      return { 
          ...state,
          selectedEquipments: equips,
      };
    })

    .addCase(selectParameters, (state, action) => {
      let params:any = SetObjectValue(state.selectedParameters,action.payload.paths,action.payload.items);
      return { 
          ...state,
          selectedParameters: params,
      };
    })

    .addCase(selectSheet, (state, action) => {
      let sheetName:any = SetObjectValue(state.selectedSheetName,action.payload.paths,action.payload.sheetName);
      return { 
          ...state,
          selectedSheetName: sheetName,
      };
    })
)


