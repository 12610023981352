// import './App.css';
import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TestReduxPage } from './pages/test/TestRedux';
import { RequireAuth } from 'routes/RouteGuard';
import AuthProvider from 'contexts/AuthProvider';
import { VisibilityProvider } from 'contexts/VisibilityProvider';

function App() {

  const LoginPage = lazy(() => import('pages/login/LoginPage').then(({ LoginPage }) => ({ default: LoginPage })));
  const SitesPage = lazy(() => import('pages/sites/SitesPage').then(({ SitesPage }) => ({ default: SitesPage })));
 
  console.log('DevicePixelRatio',window.devicePixelRatio);
  
  return (
    // <div className='w-screen h-screen bg-page-bg'>
    <div className='w-full h-full bg-page-bg'>
      {/* <AuthService> */}
      <VisibilityProvider>
        <AuthProvider>
          <Suspense fallback={<div></div>}>
            <Routes>
              {/* <Route path='/' element={<SitesPage/>}/> */}
              <Route path='/' element={<RequireAuth><SitesPage/></RequireAuth>}/>
              <Route path='/login' element={<LoginPage/>}/>
              {/* <Route path='/test' element={<TestReduxPage/> }/> */}
            </Routes>
          </Suspense>
        </AuthProvider>
      </VisibilityProvider>
      {/* </AuthService> */}
    </div>
  );
}

export default App;
