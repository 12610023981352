import { createReducer } from "@reduxjs/toolkit";
import { fetchConfig, fetchDataAtTimes, fetchDataCurrents, fetchDataPlots, refreshData, reset, setTimeRange } from "./charts.actions";
import { DateTimeRange } from "models/DateTimeRange";
import { DateTimeX } from "utils/DateTimeX";

interface ChartsState {
    isConfigLoading:boolean;
    configLoadingError?:string;
    // config?:DashboardConfig;
    // defaultConfig?:IResponse[];
    config?:any;
    isDataLoading:boolean;
    dataLoadingError?:string;
    data?:any;
    timeRange:DateTimeRange;
    refreshData:boolean;
}


const initialState:ChartsState = {
    refreshData:false,
    isConfigLoading:false,
    configLoadingError:undefined,
    config:undefined,
    isDataLoading:false,
    dataLoadingError:undefined,
    data:undefined,
    timeRange:{startTime:DateTimeX.today(), endTime:DateTimeX.EOD()} as DateTimeRange
};


export const sites_site1_chartsReducer = createReducer(initialState, (builder) =>
  builder
  .addCase(refreshData, (state) => {
        return { 
            ...state,
            refreshData: !state.refreshData
        };
    })

    .addCase(fetchConfig.pending, (state) => {
        return { 
            ...state,
            isConfigLoading: true,
            // config:undefined,
            // data:undefined,
            configLoadingError:undefined
        };
    })
    .addCase(fetchConfig.fulfilled, (state, action) => {

        // console.log(action.payload.paths);
        // var path = action.payload.paths.join('_').replace('-','_').toLowerCase();
        var path = action.payload.paths[action.payload.paths.length-1] ?? '';
        path = path.toLowerCase();
        // console.log(path);
        var config:any = state.config === undefined ? {} : {...state.config};
        config[path] = action.payload.config;

        return { 
            ...state,
            isConfigLoading: false,
            configLoadingError:undefined,
            config:config,
        };
    })
    .addCase(fetchConfig.rejected, (state, action) => {
        return { 
            ...state,
            isConfigLoading: true,
            config:undefined,
            configLoadingError:action.error.message,
        };
    })

    
    // .addCase(fetchData.pending, (state) => {
    //     return { 
    //         ...state,
    //         isDataLoading: true,
    //         dataLoadingError:undefined,
    //     };
    // })
    // .addCase(fetchData.fulfilled, (state, action) => {
    //     var component = action.payload.component;
    //     var data:any = state.data === undefined ? {} : {...state.data};
        
    //     data[component] = action.payload.data;
        
    //     return { 
    //         ...state,
    //         isDataLoading: false,
    //         dataLoadingError:undefined,
    //         data:data,
    //     };
    // })
    // .addCase(fetchData.rejected, (state, action) => {

       
        
    //     return { 
    //         ...state,
    //         isDataLoading: true,
    //         dataLoadingError:action.error.message,
    //         // data:undefined,
    //     };
    // })


    .addCase(fetchDataCurrents.pending, (state) => {
        
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:undefined,
        };
    })
    .addCase(fetchDataCurrents.fulfilled, (state, action) => {
        let site = action.payload.site;
        let component = action.payload.component;
        let data:any = state.data === undefined ? {} : {...state.data};
        
        if(data[site] === undefined) { data[site] = {}; }
        else { data[site] = {...data[site]}; } 

        data[site][component] = action.payload.data;
        
        return { 
            ...state,
            isDataLoading: false,
            dataLoadingError:undefined,
            data:data,
        };
    })
    .addCase(fetchDataCurrents.rejected, (state, action) => {

        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:action.error.message,
            // data:undefined,
        };
    })


    .addCase(fetchDataPlots.pending, (state) => {
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:undefined,
        };
    })
    .addCase(fetchDataPlots.fulfilled, (state, action) => {
        var site = action.payload.site;
        var component = action.payload.component;
        var data:any = state.data === undefined ? {} : {...state.data};

        // if(data[site] === undefined){data[site] = {}}
        
        // data[site][component] = action.payload.data;


        if(data[site] === undefined) { data[site] = {}; }
        else { data[site] = {...data[site]}; } 

        data[site][component] = action.payload.data;
        
        return { 
            ...state,
            isDataLoading: false,
            dataLoadingError:undefined,
            data:data,
        };
    })
    .addCase(fetchDataPlots.rejected, (state, action) => {
        return { 
            ...state,
            isDataLoading: true,
            dataLoadingError:action.error.message,
            // data:undefined,
        };
    })

    .addCase(fetchDataAtTimes.pending, (state) => {
        
      return { 
          ...state,
          isDataLoading: true,
          dataLoadingError:undefined,
      };
  })
  .addCase(fetchDataAtTimes.fulfilled, (state, action) => {
      let site = action.payload.site;
      let component = action.payload.component;
      let data:any = state.data === undefined ? {} : {...state.data};
      
      if(data[site] === undefined) { data[site] = {}; }
      else { data[site] = {...data[site]}; } 

      data[site][component] = action.payload.data;
      
      return { 
          ...state,
          isDataLoading: false,
          dataLoadingError:undefined,
          data:data,
      };
  })
  .addCase(fetchDataAtTimes.rejected, (state, action) => {

      return { 
          ...state,
          isDataLoading: true,
          dataLoadingError:action.error.message,
          // data:undefined,
      };
  })

  .addCase(setTimeRange, (state, action) => {

    return { 
        ...state,
        timeRange: action.payload.range
    };
  })

  .addCase(reset, (state, action) => {

    var site = action.payload.site;
        var component = 'c1';
        var data:any = state.data === undefined ? {} : {...state.data};

        // if(data[site] === undefined){data[site] = {}}
        
        // data[site][component] = action.payload.data;


        if(data[site] === undefined) { data[site] = {}; }
        else { data[site] = {...data[site]}; } 

        data[site][component] = undefined;
        
    return { 
        ...state,
        data:data,
        timeRange: {startTime:DateTimeX.today(), endTime:DateTimeX.EOD()} as DateTimeRange
    };
  })
)


