import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
// import {Buffer} from 'buffer';
// import { DashboardConfig } from '../models/DashboardConfig';
// import { IResponse } from '../models/IResponse';
import { PvisClient } from 'api/PvisClient';
import { AuthSelectors } from './auth.exports';
import { LoginRequest } from '../models/LoginRequest';
import { RefreshTokenRequest } from '../models/RefreshTokenRequest';


const MODULE_NAME = "[Auth]";
const _http = PvisClient.getInstance();


export const init = createAction(`${MODULE_NAME} Init`);
export const logout = createAction(`${MODULE_NAME} Logout`);

export const login = createAsyncThunk(
    `${MODULE_NAME} Login`,
    async({request}:{request:LoginRequest},thunkAPI) => {

      // console.log(request);
        const data = await _http.login(request);

        // console.log(data);
        return data;
    
    }
)

export const refreshToken = createAsyncThunk(
    `${MODULE_NAME} Refresh Token`,
    async({request}:{request:RefreshTokenRequest},thunkAPI) => {
        
        const data = await _http.refreshToken(request);
        return data;
    
    }
)

