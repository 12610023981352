import { useAuth } from 'contexts/AuthProvider';
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom';
import { DateTimeX } from 'utils/DateTimeX';

export const RequireAuth = ({children}:{children:JSX.Element}) => {

  const { isAuth } = useAuth();
  // useEffect(() => { console.log(isAuth); },[isAuth])

  return(
    <>
        {(isAuth) && children }
        {(!isAuth) && <Navigate to="/login" /> }
    </>
  )
 
};