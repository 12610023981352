import { combineReducers } from "@reduxjs/toolkit";
import { sites_SitesNavReducer } from "pages/sites/parts/site-nav/states/sites-nav/sites-nav.reducers";
import { site1_Reducers } from "pages/sites/parts/site-1/states/site1.reducers";
import { overview1_Reducers } from "../parts/overview-1/states/overview1.reducers";
import { overview2_Reducers } from "../parts/overview-2/states/overview2.reducers";
import { sites_TopNavReducer } from "../parts/top-nav/states/top-nav.reducers";

export const sitesReducers = combineReducers({
    sitesNav:sites_SitesNavReducer,
    topNav:sites_TopNavReducer,
    site1:site1_Reducers,
    overview1:overview1_Reducers,
    overview2:overview2_Reducers,
});