export const SetObjectValue = (obj:any, keys:string[], value:any):any => {

  if(keys === undefined || keys.length === 0) { return obj; }

  let newObj = obj === undefined ? {} : {...obj};
  let key = keys[0];

  if(keys.length === 1) {
    newObj[key] = value;
    return newObj;
  }
  
  let childKeys = keys.slice(1,keys.length);
  newObj[key] = SetObjectValue(newObj[key], childKeys, value);
  return newObj;
}

export const GetObjectValue = (obj:any, keys:string[]):any => {

  if(obj === undefined || keys === undefined || keys.length === 0) { return undefined; }

  let newObj = obj;
  let key = keys[0];

  if(keys.length === 1) {
    return newObj[key]
  }
  
  let childKeys = keys.slice(1,keys.length);
  return GetObjectValue(newObj[key], childKeys);
}