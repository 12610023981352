import { createReducer } from "@reduxjs/toolkit";
import { fetchConfig, updateEquipments, updateExpand, updateParameters } from "./assets-picker.actions";
import { GetObjectValue, SetObjectValue } from "utils/objectUils";
import { CategoryConfig, EquipmentConfig } from "../models/CategoryConfig";
import { getEquipGroups } from "../helpers/getEquipGroups";
import { getParamGroups } from "../helpers/getParamGroups";
import { AssetCatagory } from "../models/AssetsPickerModel";
import { v4 as uuidv4 } from "uuid";
import { log } from "console";
import { CheckBoxGroupItem } from "components/forms/check-box/models/CheckBoxModels";


interface AssetsPickerState {
    isConfigLoading:boolean;
    configLoadingError?:string;
    config?:any;
    data?:any;
    expandedGroups?:any;
    // selectedEquipments?:any;
    // selectedParameters?:any;
}


const initialState:AssetsPickerState = {
    isConfigLoading:false,
    configLoadingError:undefined,
    config:undefined,
    data:undefined,
    expandedGroups:undefined,
    // selectedEquipments:undefined,
    // selectedParameters:undefined,
};


export const assetsPickerReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchConfig.pending, (state) => {
        return { 
            ...state,
            isConfigLoading: true,
            configLoadingError:undefined
        };
    })
    .addCase(fetchConfig.fulfilled, (state, action) => {

        // console.log(action.payload.paths);
        // var path = action.payload.paths.join('_').replace('-','_').toLowerCase();
        var path = action.payload.paths[action.payload.paths.length-1] ?? '';
        path = path.toLowerCase();
        // console.log(path);
        var config:any = state.config === undefined ? {} : {...state.config};
        // config[path] = action.payload.config;
        
        const cats = action.payload.config as CategoryConfig[];
        config[path] = cats.map(cat => {
          
          return {
            id:uuidv4(),
            name:cat.name,
            equipments:cat.equipments.map(e => {return {...e, id:uuidv4()}}),
            parameters:cat.parameters.map(p => {return {...p, id:uuidv4()}}),
            
          } as CategoryConfig

        });
        // console.log(cats);

        // if(cats !== undefined && cats.length !== 0) {

        //   const categories = cats.map(cat => {
        //     const equipmentGroups = getEquipGroups(cat);
        //     const parameterGroups = getParamGroups(cat);
            
        //     return {
        //       name:cat.name,
        //       expanded:false,
        //       equipmentGroups,
        //       parameterGroups
        //     } as AssetCatagory;
        //   })
  
        //   config[path] = categories;
        // }

        return { 
            ...state,
            isConfigLoading: false,
            configLoadingError:undefined,
            config:config,
            data:config,
        };
    })
    .addCase(fetchConfig.rejected, (state, action) => {
        return { 
            ...state,
            isConfigLoading: true,
            config:undefined,
            configLoadingError:action.error.message,
        };
    })

    
    // .addCase(initEquipments, (state, action) => {
    //   let equips:any = SetObjectValue(state.equipments,action.payload.paths,action.payload.items);
    //   return { 
    //       ...state,
    //       equipments: equips,
    //   };
    // })

    // .addCase(initParameters, (state, action) => {
    //   let params:any = SetObjectValue(state.parameters,action.payload.paths,action.payload.items);
    //   return { 
    //       ...state,
    //       parameters: params,
    //   };
    // })

    // .addCase(selectEquipments, (state, action) => {
    //   let equips:any = SetObjectValue(state.selectedEquipments,action.payload.paths,action.payload.items);
    //   return { 
    //       ...state,
    //       selectedEquipments: equips,
    //   };
    // })

    // .addCase(selectParameters, (state, action) => {
    //   let params:any = SetObjectValue(state.selectedParameters,action.payload.paths,action.payload.items);
    //   return { 
    //       ...state,
    //       selectedParameters: params,
    //   };
    // })



    .addCase(updateExpand, (state, action) => {

      let newState = {...state.data};
      // console.log(newState);

      let siteState = [...newState[action.payload.paths]] as CategoryConfig[]; 
      // console.log(siteState);

      if(siteState === undefined || siteState.length === 0) {return {...state};}
      // console.log(siteState);
      
      let catIndex = siteState.findIndex(x => x.id === action.payload.catId);
      if(catIndex === undefined || catIndex === -1) { return {...state} }
      // console.log(catIndex);

      let catState = {...siteState[catIndex]};
      // console.log(catState);
      
      catState = {...catState, expanded:action.payload.expanded};
      siteState[catIndex] = catState;

      // newState = SetObjectValue(state.config, action.payload.paths, pathsState);
      newState[action.payload.paths] = siteState;

      // console.log(newState);

      return { 
          ...state,
          data: {...newState}
      };
    })



    .addCase(updateEquipments, (state, action) => {

      let newState = {...state.data};
      // console.log(newState);

      let siteState = [...newState[action.payload.paths]] as CategoryConfig[]; 
      // console.log(siteState);

      if(siteState === undefined || siteState.length === 0) {return {...state};}
      // console.log(siteState);
      
      let catIndex = siteState.findIndex(x => x.id === action.payload.catId);
      if(catIndex === undefined || catIndex === -1) { return {...state} }
      // console.log(catIndex);

      let catState = {...siteState[catIndex]};
      // console.log(catState);
      
      let equipments = [...catState.equipments];
      // console.log(equipments);

      action.payload.items.forEach(newItem => {

        const index = equipments.findIndex(oldItem => oldItem.id === newItem.id);
        if(index === undefined || index === -1) { return; }

        equipments[index] = {...equipments[index], selected:newItem.selected};

      })

      catState.equipments = equipments;
      siteState[catIndex] = catState;

      // newState = SetObjectValue(state.config, action.payload.paths, pathsState);
      newState[action.payload.paths] = siteState;

      // console.log(newState);

      return { 
          ...state,
          data: {...newState}
      };
    })

    .addCase(updateParameters, (state, action) => {

      let newState = {...state.data};
      // console.log(newState);

      let siteState = [...newState[action.payload.paths]] as CategoryConfig[]; 
      // console.log(siteState);

      if(siteState === undefined || siteState.length === 0) {return {...state};}
      // console.log(siteState);
      
      let catIndex = siteState.findIndex(x => x.id === action.payload.catId);
      if(catIndex === undefined || catIndex === -1) { return {...state} }
      // console.log(catIndex);

      let catState = {...siteState[catIndex]};
      // console.log(catState);
      
      let parameters = [...catState.parameters];
      // console.log(equipments);

      action.payload.items.forEach(newItem => {

        const index = parameters.findIndex(oldItem => oldItem.id === newItem.id);
        if(index === undefined || index === -1) { return; }

        parameters[index] = {...parameters[index], selected:newItem.selected};

      })

      catState.parameters = parameters;
      siteState[catIndex] = catState;

      // newState = SetObjectValue(state.config, action.payload.paths, pathsState);
      newState[action.payload.paths] = siteState;

      // console.log(newState);

      return { 
          ...state,
          data: {...newState}
      };
    })



    // .addCase(updateEquipments, (state, action) => {

    //   let newState = state.selectedEquipments === undefined ? {} : {...state.selectedEquipments};
    //   // console.log(newState);

    //   let siteState = newState[action.payload.paths] === undefined ? [] : [...newState[action.payload.paths]] as CheckBoxGroupItem[]; 
    //   // console.log(siteState);

    //   const notSelectedItems = action.payload.items.filter(x => x.selected === undefined || x.selected === false);
    //   const selectedItems = action.payload.items.filter(x => x.selected === true);

    //   siteState = siteState.filter(x => notSelectedItems.findIndex(y => y.id === x.id) === -1);
    //   siteState = [...siteState, ...selectedItems];


    //   // newState = SetObjectValue(state.config, action.payload.paths, pathsState);
    //   newState[action.payload.paths] = siteState;

    //   // console.log(newState);

    //   return { 
    //       ...state,
    //       selectedEquipments: {...newState}
    //   };
    // })

    // .addCase(updateParameters, (state, action) => {

    //   let newState = state.selectedParameters === undefined ? {} : {...state.selectedParameters};
    //   // console.log(newState);

    //   let siteState = newState[action.payload.paths] === undefined ? [] : [...newState[action.payload.paths]] as CheckBoxGroupItem[]; 
    //   // console.log(siteState);

    //   const notSelectedItems = action.payload.items.filter(x => x.selected === undefined || x.selected === false);
    //   const selectedItems = action.payload.items.filter(x => x.selected === true);

    //   siteState = siteState.filter(x => notSelectedItems.findIndex(y => y.id === x.id) === -1);
    //   siteState = [...siteState, ...selectedItems];


    //   // newState = SetObjectValue(state.config, action.payload.paths, pathsState);
    //   newState[action.payload.paths] = siteState;

    //   // console.log(newState);

    //   return { 
    //       ...state,
    //       selectedParameters: {...newState}
    //   };
    // })

    
)


