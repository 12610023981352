import axios,{AxiosError, AxiosHeaders, AxiosInstance, AxiosInterceptorManager, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig, RawAxiosRequestHeaders} from "axios";
import { SiteNavModel } from "../models/SiteNavModel";
import { Buffer } from 'buffer';
import { TsTimeMode } from "enums/TsTimeMode";
import { formatNumber } from "utils/formatNumber";
import { LoginRequest } from "pages/login/models/LoginRequest";
import { LoginResponse } from "pages/login/models/LoginResponse";
import { RefreshTokenRequest } from "pages/login/models/RefreshTokenRequest";
import { request } from "http";
import { getAccessToken } from "pages/login/states/auth.selectors";
import { DateTimeX } from "utils/DateTimeX";
import { AuthToken } from "pages/login/models/AuthToken";
import { JwtService, JwtToken } from "utils/jwtService";
import { sleep } from "utils/sleep";
import { User } from "models/User";


export class PvisClient {

  private static instance: PvisClient;
  public static getInstance(): PvisClient {
      if (!PvisClient.instance) { PvisClient.instance = new PvisClient(); }
      return PvisClient.instance;
  }

  private _jwtSvc: JwtService;
  // private _jwt:JwtToken | undefined;
  private _http: AxiosInstance;

  
  // private baseUrl = `https://x1.superpvis.com:7322/bff/`;
  // private baseUrl = `http://192.168.1.157:10001/api/`;
  // private baseUrl = `http://pvis-core-01:10001/api/`;
  private baseUrl = `https://superpvis.com/api/`;
  // private baseUrl = `http://x1.superpvis.com:7321/bff/`;

  // private _timer:NodeJS.Timer;

  private onJwtStorageUpdated(token:JwtToken|undefined) {
    // console.log('onJwtStorageUpdated');
    // console.log(token);

    if(token !== undefined && 
      token.accessToken !== undefined && 
      token.refreshToken !== undefined && 
      // this._jwt !== undefined && 
      // this._jwt.isRefreshing === true && 
      this._jwtSvc.isExpired() === false &&
      token.isRefreshing === false) {

        // console.log('before retry queue')
      this.retryRequestQueue();
    }

    // this._jwt = token;
    this._subs.forEach(x => x(token));
  }

  private constructor() {

    this._jwtSvc = JwtService.getInstance();
    this._jwtSvc.onTokenUpdated((token) => this.onJwtStorageUpdated(token));
    // this._jwt = this._jwtSvc.getToken();

      this._http = axios.create({
        baseURL:this.baseUrl, 
        headers: { 
          "Content-Type": "application/json", 
          "Access-Control-Allow-Origin": "*",
        },
      } as CreateAxiosDefaults);

      this.setRequestIntercept();
      this.setResponseIntercept();
  }
 

  private _subs:((token:AuthToken|undefined) => void)[] = [];

  public onTokenUpdated(func:(token:AuthToken|undefined) => void){
    this._subs.push(func);
  }


 
  private setRequestIntercept(){
    
    if(this._http === undefined) { return; }
    
      this._http.interceptors.request.use( async (config) => {

        // console.log('setRequestIntercept');

        const accessToken = this._jwtSvc?.getToken()?.accessToken;

        // if (accessToken) { config.headers.Authorization = `Bearer ${accessToken}`; }
        // else { config.headers.Authorization = undefined; }


        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
          // config.headers = { ...config.headers, Authorization: `Bearer ${accessToken}` };

          // console.log('isExpired', this._jwtSvc.isExpired());
          // console.log('isRefreshing', this._jwtSvc.isRefreshing());

          if(this._jwtSvc.isExpired() && this._jwtSvc.isRefreshing() && this._jwtSvc.isRefreshingTimeout()) { this._jwtSvc.clearRefreshing(); }

          if(this._jwtSvc.isExpired()) {
            if(!this._jwtSvc.isRefreshing()) {
              // console.log('RefreshToken from Request Interceptor.')
              const token = await this.refreshTokenTask();
              if(token) {
                config.headers.Authorization = `Bearer ${token.accessToken}`;
                // config.headers = { ...config.headers, Authorization: `Bearer ${token.accessToken}` };
              }
              return config;
            }
            else {

              // while(this._jwtSvc.isRefreshing()) {
                // await sleep(100);
              // }
              // const originalRequest = config as AxiosRequestConfig;
              //  const retryOriginalRequest = new Promise<InternalAxiosRequestConfig<any>>((resolve, reject) => {
              //   this._requestQueue3.push({ resolve, reject, config });
              // });
              // return retryOriginalRequest;

              //  const retryOriginalRequest = new Promise<InternalAxiosRequestConfig>((resolve, reject) => {
              //   this._requestQueue3.push({ resolve, reject, config });
              // });
              // return retryOriginalRequest;

              // const retry = new Promise<void>((resolve, reject) => {
              //   this._requestQueue.push({ resolve, reject });
              // });

              // return retry;
      
              // return retry.then(() => this._http(config));
              // return retry.then(() => config);
              // return this._http(config);
            }
          }
        }
        else { 
          config.headers.Authorization = undefined; 
          // config.headers = { ...config.headers, Authorization: undefined };
        }
        
        return config;
      },
      
  async (error): Promise<any> => { 
    const originalRequest = error.config as AxiosRequestConfig;
    if (error.response && error.response.status === 401 && originalRequest !== undefined) {
      if (this._jwtSvc.isRefreshing()) {
        // isRefreshing = true;
        try {
          // const newToken = await refreshToken();
          const token = await this.refreshTokenTask();
          // originalRequest.headers.Authorization = `Bearer ${newToken}`;
          if(token) {
            // originalRequest?.headers?.Authorization = `Bearer ${token.accessToken}`;
            originalRequest.headers = { ... originalRequest.headers, Authorization: `Bearer ${token.accessToken}` };
          }
          // Retry the original request with the new token
          return this._http(originalRequest);
        } catch (refreshError) {
          console.error('Failed to refresh token', refreshError);
          throw refreshError;
        } finally {
          // isRefreshing = false;
        }
      } else {
        // If refreshing, queue the request
        // const retryOriginalRequest = new Promise<AxiosRequestConfig>((resolve, reject) => {
        //   this._requestQueue2.push({ resolve, reject, config: originalRequest });
        // });
        // return retryOriginalRequest;

        const retry = new Promise<void>((resolve, reject) => {
          this._requestQueue.push({ resolve, reject });
        });

        return retry.then(() => this._http(error.config));
      }
    }
    return Promise.reject(error);
  }
      
      );
  }


  private _requestQueue: { resolve: () => void; reject: (error: any) => void }[] = [];
  // private _requestQueue2: { resolve: (value?: InternalAxiosRequestConfig<any>) => void; reject: (reason?: any) => void; config: InternalAxiosRequestConfig<any> }[] = [];
  // private _requestQueue3: { resolve: (value: InternalAxiosRequestConfig) => void; reject: (reason?: any) => void; config: InternalAxiosRequestConfig }[] = [];

  // private _requestQueue2: {
  //   resolve: (value: AxiosRequestConfig<any> | PromiseLike<AxiosRequestConfig>) => void;
  //   reject: (reason?: any) => void;
  //   config: AxiosRequestConfig;
  // }[] = [];

 

  private async refreshTokenTask():Promise<JwtToken|undefined> {

    this._jwtSvc.startRefreshing();

    for(var i=0; i<3; i++) {
      const res = await this.refreshToken({accessToken:this._jwtSvc.getToken()?.accessToken, refreshToken:this._jwtSvc.getToken()?.refreshToken});
      // console.log(res);
      
      if((res === undefined || res.accessToken === undefined || res.refreshToken === undefined) && 
        this._jwtSvc.isRefreshing() === false && this._jwtSvc.isExpired() === false) {
          return this._jwtSvc.getToken();
        }

      if(res !== undefined && res.accessToken !== undefined && res.refreshToken !== undefined) {
        // console.log('Refresh Token Success.')
        
        const newToken = {
          accessToken: res.accessToken, 
          refreshToken: res.refreshToken, 
          expiry:DateTimeX.addSeconds(DateTimeX.nowUtc(), res.expireSec - 30), 
          isRefreshing: false, 
          refreshTimeout:undefined
        };
        
        this._jwtSvc.successRefreshing(newToken);
        return newToken;
      }
      // console.log(`RefreshToken failed. Retry ${i+1} of 3`);
      await sleep(1000);
    // else {
    //   this._jwtSvc.clearRefreshing();
    //   return undefined;
    // }
    }
    this._jwtSvc.clearRefreshing();
    // this._jwtSvc.clearToken();
    return undefined;

  }

  private async setResponseIntercept(){
      this._http.interceptors.response.use(
          (res) => res,
          async (error: AxiosError) => {
            const { data, status, config } = error.response!;

            
            switch (status) {
              case 400:
                console.error(data);
                break;
        
              case 401:

                // console.log('Unauthorized Unauthorized Unauthorized Unauthorized Unauthorized Unauthorized Unauthorized Unauthorized');

                if(this._jwtSvc.isExpired() && this._jwtSvc.isRefreshing() && this._jwtSvc.isRefreshingTimeout()) { this._jwtSvc.clearRefreshing(); }
                if(this._jwtSvc.getToken() && !this._jwtSvc.isRefreshing()) {

                    const maxRetry = 3;

                    for(var retryCount = 1; retryCount <= maxRetry; retryCount++) {

                      const retry = new Promise<void>((resolve, reject) => {
                        this._requestQueue.push({ resolve, reject });
                      });

                      // const retryOriginalRequest = new Promise<AxiosRequestConfig>((resolve, reject) => {
                      //   this._requestQueue2.push({ resolve, reject, config });
                      // });
                      // return retryOriginalRequest;
                    
                      const originalRequest = config;
                      const res = await this.refreshTokenTask();
                      if(res) {
                        // console.log(config.data);
                        config.headers.Authorization = `Bearer ${res.accessToken}`;
                        return this._http(originalRequest);
                      }
  
                      if(retryCount < maxRetry) {
                        // console.log(`Failed refresh token attempt [${retryCount}]`);
                        // console.log(config.data);
                        await sleep(1000);
                      }
                      else {
                        this._jwtSvc.clearRefreshing();
                        // console.log(config.data);
                        return retry.then(() => this._http(originalRequest));
                        // return retryOriginalRequest.then(() => this._http(originalRequest));
                        // return retryOriginalRequest;
                      }
                    }
                }
                else {
                  // console.log('Queue the request while refreshing token.')
                  // const retry = new Promise<void>((resolve, reject) => {
                  //   this._requestQueue.push({ resolve, reject });
                  // });
                  // return retry;


                  const retry = new Promise<void>((resolve, reject) => {
                    this._requestQueue.push({ resolve, reject });
                  });

                  return retry.then(() => this._http(config));


                  // const retry = new Promise<AxiosRequestConfig>((resolve, reject) => {
                  //   this._requestQueue2.push({ resolve, reject, config });
                  // });
                  // // return retryOriginalRequest;
                  // return retry.then(() => this._http(config));
                }

                break;
        
              case 404:
                console.error('/not-found');
                break;
        
              case 500:
                console.error('/server-error');
                break;
            }
            return Promise.reject(error);
          }
        );
  }

  private retryRequestQueue(){

    // console.log('retryRequestQueue3.', this._requestQueue3.length, ' items');
    // this._requestQueue3.forEach(({ resolve, reject, config }) => resolve(config));
    // this._requestQueue3 = [];


    // console.log('retryRequestQueue.', this._requestQueue.length, ' items');
    this._requestQueue.forEach(({ resolve }) => resolve());
    this._requestQueue = [];
    
    // console.log('retryRequestQueue2.', this._requestQueue2.length, ' items');
    // this._requestQueue2.forEach(({ resolve, reject, config }) => resolve(config));
    // this._requestQueue2 = [];

     // console.log('retryRequestQueue3.', this._requestQueue3.length, ' items');
    // this._requestQueue3.forEach(({ resolve, config }) => {
    //   config.headers = config.headers || {};
    //   config.headers.Authorization = `Bearer ${this._jwtSvc.getToken()?.accessToken}`;
    //   this._http.request(config)
    //     .then(resolve)
    //     .catch((error) => {
    //       console.error('Failed to retry queued request', error);
    //     });

    //     // this._http(config);
    // });
    // this._requestQueue3 = [];
  }

  getSitesNav() {
    const path = this.baseUrl + '../assets/api/sites/nav/main.json';
    return  this._http.get<SiteNavModel[]>(path);
  }


  async getSitesConfig(paths:string[]) {
    const path = this.baseUrl + 'web/config/getconfig';

    const request = paths;
        
    const res = await this._http.post(path,request)
            // console.log(res);
            const data = res.data.data
            return data;
  }
  
  async getDataCurrents(tagnames:string[]) {
    const path = this.baseUrl + 'web/data/getcurrents';

    const request = { tagNames:tagnames };
        
    const res = await this._http.post(path,request)
    // console.log(res);
    const data = res.data.data
    return data;
  }

  async getDataPlots(tagnames:string[], startTime:Date, endTime:Date) {
    const path = this.baseUrl + 'web/data/getplots';

    const request = { tagNames: tagnames, startTime:startTime, endTime: endTime, count:1024 };
        
    const res = await this._http.post(path,request)
    // console.log(res);
    const data = res.data.data
    return data;
  }

  async getDataInterval(tagnames:string[], startTime:Date, endTime:Date, interval:string, timeMode?:TsTimeMode ) {
    const path = this.baseUrl + 'web/data/getinterval';

    const request = { tagNames: tagnames, startTime:startTime, endTime: endTime, interval:interval, timeMode:timeMode ?? TsTimeMode.Default };
        
    const res = await this._http.post(path,request)
    // console.log(res);
    const data = res.data.data
    return data;
  }

  async getDataAtTimes(tagnames:string[], timestamp:Date, timeMode?:TsTimeMode ) {
    const path = this.baseUrl + 'web/data/getattimes';

    const request = { tagNames: tagnames, timestamp:timestamp, timeMode:timeMode ?? TsTimeMode.Default };
        
    const res = await this._http.post(path,request)
    // console.log(res);
    const data = res.data.data
    return data;
  }

  async getDataCsv(tagnames:string[], startTime:Date, endTime:Date, timeMode?:TsTimeMode, messageLog?:(text:string|undefined) => void ) {
    const path = this.baseUrl + 'datalogger/getcsv';

    const request = { tagNames: tagnames, startTime:startTime, endTime: endTime, timeMode:timeMode ?? TsTimeMode.Default };
        
    

    try {
      const response: AxiosResponse<Blob> = await this._http.post(path,request, {
        responseType: 'blob',  // Specify the response type as 'blob' for binary data
        onDownloadProgress: (progressEvent:AxiosProgressEvent) => {
          // Calculate and update the progress
          const currentProgress: string = progressEvent.total === undefined ? '---' : formatNumber((progressEvent.loaded / progressEvent.total) * 100,1);
          const dlMb: string = formatNumber(progressEvent.loaded / 1024 / 1024,1);
          const totalMb: string = progressEvent.total === undefined ? '---' : formatNumber(progressEvent.total / 1024 / 1024,1);
          // setProgress(currentProgress);
          const statusText = `${dlMb}/${totalMb}MB (${currentProgress}%)`;
          // console.log(statusText);

          messageLog?.(statusText)
        },
      });

      

      // console.log(response.headers);
       // Get filename from Content-Disposition header if available
       const contentDisposition: string | null = response.headers['content-disposition'];

      //  console.log(contentDisposition);
       const filenameMatch: RegExpMatchArray | null | undefined = contentDisposition?.match(/filename="(.+?)"/);
 
       // Set the filename for download
       const filename: string = filenameMatch ? filenameMatch[1] : 'downloaded_file.txt';
 

      // Trigger file download by creating a Blob and a link
      const url: string = window.URL.createObjectURL(new Blob([response.data]));
      const a: HTMLAnchorElement = document.createElement('a');
      a.href = url;
      a.download = filename;
      // a.download = 'downloaded_file.txt'; // Set the desired filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // The download is complete
      // setProgress(100);
      messageLog?.(undefined)
    } catch (error) {
      console.error('Error:', error);
    }
  };

  async getUser() {

    const accessToken = { accessToken: this._jwtSvc.getToken()?.accessToken };
    const path = this.baseUrl + 'users/getByToken';
    const res = await this._http.post(path,accessToken);
    // console.log(res);
    const data = res?.data?.data?.user as User;
    return data;
  }

  async login(request:LoginRequest) {
    const path = this.baseUrl + 'users/login';
        
    const res = await this._http.post(path,request)

    // console.log(res);
    const data = res.data.data as LoginResponse;

    // console.log(res);
                
    if(res !== undefined && data != undefined && data.accessToken !== undefined && data.refreshToken !== undefined) {
      // console.log('Login Success.')
      // this.setAccessToken(data.accessToken);
      // this.setRefreshToken(data.refreshToken);
      // const expiry = DateTimeX.addSeconds(DateTimeX.nowUtc(), data.expireSec);
      // this.setExpiry(expiry.toISOString());
      // this._subs.forEach(x => x({accessToken:data.accessToken,refreshToken:data.refreshToken,expiry:expiry}))
      
      const newToken = {
        accessToken: data.accessToken, 
        refreshToken: data.refreshToken, 
        expiry:DateTimeX.addSeconds(DateTimeX.nowUtc(), data.expireSec - 30), 
        isRefreshing: false, 
        refreshTimeout:undefined
      };
      this._jwtSvc.setToken(newToken);
      this._subs.forEach(x => x(newToken));
    }
    return data;
  }

  async refreshToken(request:RefreshTokenRequest) {
    
    // this._isRefreshingToken = true;
    const path = this.baseUrl + 'users/refreshToken';
        
    const res = await this._http.post(path,request);
    // console.log(res);

    

    const data = res.data.data as LoginResponse;
    // this._isRefreshingToken = false;
    return data;
  }
}

