import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

const MODULE_NAME = "[FakeProducts]";

export const setLoadingTrue = createAction(`${MODULE_NAME} Set Loading True`);
export const setLoadingFalse = createAction(`${MODULE_NAME} Set Loading False`);

export const fetchProducts = createAsyncThunk(
    `${MODULE_NAME} Fetch Products`,
    async({userId,tagName}:{userId:number, tagName:string},thunkAPI) => {
        // thunkAPI.dispatch(FakeProductsActions.setLoadingTrue);
        const response = await fetch('https://fakestoreapi.com/products')
        const data = await response.json();
        // thunkAPI.dispatch(FakeProductsActions.setLoadingFalse);
        // console.log(data);
        return data;
    }
)