import { combineReducers } from "@reduxjs/toolkit";
import { sites_site1_dashboardReducer } from "../tabs/dashboard/states/dashboard.reducers";
import { sites_site1_performanceReducers } from "../tabs/performance/states/performance.reducers";
import { sites_site1_realtimeReducer } from "../tabs/real-time/states/realtime.reducers";
import { sites_site1_chartsReducer } from "../tabs/charts/states/charts.reducers";
import { sites_site1_dataReducer } from "../tabs/data/states/data.reducers";


export const site1_Reducers = combineReducers({
    dashboard:sites_site1_dashboardReducer,
    performance:sites_site1_performanceReducers,
    realtime:sites_site1_realtimeReducer,
    charts:sites_site1_chartsReducer,
    data:sites_site1_dataReducer,
    
});