import { combineReducers } from "@reduxjs/toolkit";
import { sites_site1_performance_topNavReducer } from "../top-nav/states/top-nav.reducers";
import { sites_site1_performance_overallReducer } from "../tabs/overall/states/overall.reducers";
import { sites_site1_performance_invertersReducer } from "../tabs/inverters/states/inverters.reducers";


export const sites_site1_performanceReducers = combineReducers({
  topNav:sites_site1_performance_topNavReducer,
  overall:sites_site1_performance_overallReducer,
  inverters:sites_site1_performance_invertersReducer
});