import { createReducer } from "@reduxjs/toolkit";
import { decrement, increment, reset } from "./counter.actions";

interface CounterState {
    count:number;
}


const initialState:CounterState = {
    count:55
};


export const counterReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(increment, (state, {payload}) => {
        return { 
            count: state.count + payload.number
        };
    })
    .addCase(decrement, (state, {payload}) => {
        return { 
            count: state.count - payload.number
        };
    })
    .addCase(reset, (state, {payload}) => {
        return { 
            count: 0
        };
    })
    
)
