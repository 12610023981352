import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';
import { SiteNavModel } from 'models/SiteNavModel';
import { SitesNavActions } from './sites-nav.exports';
import { PvisClient } from 'api/PvisClient';
// import jsondata from 'assets/api/sites/left-nav/sites-left-nav.json'

const MODULE_NAME = "[Sites/SitesNav]";
const _http = PvisClient.getInstance();


export const setLoadingTrue = createAction(`${MODULE_NAME} Set Loading True`);
export const setLoadingFalse = createAction(`${MODULE_NAME} Set Loading False`);
export const filterItem = createAction(`${MODULE_NAME} Filter Item`, (payload:{keyword: string}) => ({payload}));
export const selectItem = createAction(`${MODULE_NAME} Select Item`, (payload:{item: SiteNavModel}) => ({payload}));
export const nextItem = createAction(`${MODULE_NAME} Next Item`, (payload:{item: SiteNavModel}) => ({payload}));
export const backItem = createAction(`${MODULE_NAME} Back Item`);

// export const fetchConfig = createAsyncThunk(
//     `${MODULE_NAME} Fetch Items`,
//     async(_,thunkAPI) => {
//         thunkAPI.dispatch(SitesNavActions.setLoadingTrue);
//         const res = await axios.get<SiteNavModel[]>('api/config/sites/sites-left-nav.json')
//         // const res = await axios.get<SiteNavModel[]>('../src/assets/api/sites/left-nav/sites-left-nav.json')
//         // console.log(res);
//         const data = res.data
//         thunkAPI.dispatch(SitesNavActions.setLoadingFalse);
//         // console.log(data);
//         return data;
//     }
// )

export const fetchConfig = createAsyncThunk(
    `${MODULE_NAME} Fetch Config`,
    async(_,thunkAPI) => {

        thunkAPI.dispatch(SitesNavActions.setLoadingTrue);
        
        const user = await _http.getUser();
        // console.log(user);

        const getNavFile = () => {
          // console.log(user?.contact.email);
          if(user?.contact.email.toLowerCase() === 'sunflower') { return "sites-nav_sun-flower"; }
          else { return "sites-nav"; }
        }

        // const res = await _http.getSitesConfig(["sites","sites-nav"]);
        // const res = await _http.getSitesConfig(["sites","sites-nav_sun-flower"]);
        const res = await _http.getSitesConfig(["sites",getNavFile()]);
        // console.log(res);
        const data = res as SiteNavModel[];
        thunkAPI.dispatch(SitesNavActions.setLoadingFalse);
        // console.log(data);
        return data;
    }
)

export const fetchDataCurrents = createAsyncThunk(
    `${MODULE_NAME} Fetch Data Currents`,
    async({tagnames}:{tagnames:string[]},thunkAPI) => {
        
        // console.log(tagnames);
        const data = await _http.getDataCurrents(tagnames);
        // console.log(data);
        return data;
    
    }
)




// export const fetchAll = createAsyncThunk(
//     `${MODULE_NAME} Fetch Items`,
//     async({},thunkAPI) => {
//         console.log('Fetch Items');
//         // thunkAPI.dispatch(SitesLeftNavActions.setLoadingTrue);
//         const data = jsondata;
//         console.log(data);
//         // thunkAPI.dispatch(SitesLeftNavActions.setLoadingFalse);
//         return [] as SiteNavModel[];
//     }
// )